"use client";

import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";

import { useI18n } from "@repo/libs/providers/locales/client";

import { Button } from "@repo/ui/components/button";
import { ToastAction } from "@repo/ui/components/toast";
import { useToast } from "@repo/ui/components/use-toast";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const t = useI18n();
  const { toast } = useToast();

  useEffect(() => {
    Sentry.captureException(error);
    toast({
      title: t("error.title"),
      description: error.message,
      duration: 6000,
      variant: "destructive",
      action: (
        <ToastAction altText={t("error.title")} onClick={reset}>
          {t("error.retry")}
        </ToastAction>
      ),
    });
  }, [error]);

  return (
    <div className="flex h-screen items-center justify-center px-6 md:px-0">
      <div className="border-theme-background-700 z-10 mx-auto grid min-h-48 w-full grid-cols-1 grid-rows-1 rounded-md border p-4 md:max-w-6xl">
        <div className="flex h-full w-full flex-col items-center justify-center gap-4">
          <h2 className="font-semibold md:text-xl">{t("error.title")}</h2>

          <p className="text-sm" dir="auto">
            {error.message}
          </p>

          <div className="flex gap-4">
            <Button onClick={() => reset()}>{t("errors.retry")}</Button>

            <Button variant={"outline"}>{t("errors.home")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
